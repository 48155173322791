import React from 'react';
import CategoryDetails from '../../components/CategoryDetails';
import CategoryNavigation from '../../components/CategoryNavigation';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import useMenu from '../../hooks/useMenu';
import useQRCode from '../../hooks/useQRCode';

function SinglePageLayoutTemplate() {
  const { uuid } = useQRCode();
  const { showLocalSwitch } = useMenu();

  return (
    <div className="single-page-layout main-card">
      <Header
        showBackButton={false}
        showShareButton={true}
        showLocalSwitch={showLocalSwitch}
        showBusinessDetails={true}
        backURL={'/m/' + uuid}
      />
      <div className="body">
        <CategoryNavigation />

        <CategoryDetails />
      </div>
      <Footer />
    </div>
  );
}

export default SinglePageLayoutTemplate;
