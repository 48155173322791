import React from 'react';
import usePrice from '../../hooks/usePrice';
import FormatPrice from '../../components/FormatPrice';

export const Product = ({ product }) => {
  const { price, extraCost } = usePrice(product.id);

  return (
    <div className="product-container" product-id={product.id}>
      <div>
        <p className="product-name">{product?.name}</p>
        <p className="product-description">{product?.description}</p>
      </div>
      <p className="product-price">
        <FormatPrice value={price + extraCost} fractionDigits={1} />
      </p>
    </div>
  );
};
