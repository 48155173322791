import { useSelector, useDispatch } from 'react-redux';

function useQRCode() {
  // Access the Redux state
  const qrcode = useSelector((state) => state.qrcode);
  const footerNavigationEnabled = qrcode?.settings?.FOOTER_NAVIGATION_ENABLED === 'true';

  return { id: qrcode.id, uuid: qrcode.uuid, footerNavigationEnabled };
}

export default useQRCode;
