import React from 'react';
import { useNavigate } from 'react-router-dom';
import useMenu from '../../hooks/useMenu';
import useQRCode from '../../hooks/useQRCode';
import useDesign from '../../hooks/useDesign';
import Icon from './Icon';
import IconText from './IconText';
import Text from './Text';

function CategoryNavigation() {
  const { uuid } = useQRCode();
  const { categoryNavigationType, displaySelectedCategoryNameEnabled } = useDesign();
  const { activeCategories, selectedCategory } = useMenu();

  const navigate = useNavigate();

  const handleCategorySelect = (categoryId) => {
    navigate(`/m/${uuid}/category/${categoryId}`);
  };

  return (
    <div className={'category-navigation category-navigation-' + categoryNavigationType.toLowerCase()}>
      <div className="category-list">
        {activeCategories &&
          activeCategories.map((category) => {
            const active = selectedCategory && selectedCategory.id == category.id;
            if (categoryNavigationType === 'ICONTEXT') {
              return (
                <IconText
                  key={category.id}
                  name={category.name}
                  src={category.icon}
                  active={active}
                  onClick={() => handleCategorySelect(category.id)}
                />
              );
            } else if (categoryNavigationType === 'ICON') {
              return <Icon key={category.id} src={category.thumbnail} active={active} onClick={() => handleCategorySelect(category.id)} />;
            } else {
              return <Text key={category.id} name={category.name} active={active} onClick={() => handleCategorySelect(category.id)} />;
            }
          })}
      </div>
      {displaySelectedCategoryNameEnabled && selectedCategory && <div className="selected-category-name">{selectedCategory.name}</div>}
    </div>
  );
}

export default CategoryNavigation;
