import React from 'react';
import CategoryList from '../../components/CategoryList';
import DineInTakeawaySwitchModal from '../../components/DineInTakeawaySwitchModal';
import Footer from '../../components/Footer';
import FooterNavigation from '../../components/FooterNavigation';
import Header from '../../components/Header';
import MenuSocialLink from '../../components/MenuSocialLink';
import useMenu from '../../hooks/useMenu';
import useQRCode from '../../hooks/useQRCode';

function HomePage() {
  const { diningOptionsModalEnabled, showLocalSwitch, socialLinkEnabled, description, name } = useMenu();
  const { footerNavigationEnabled } = useQRCode();

  return (
    <div className="two-page-layout home-page main-card">
      <Header showBackButton={false} showShareButton={true} showLocalSwitch={showLocalSwitch} showBusinessDetails={true} />

      {socialLinkEnabled && <MenuSocialLink />}

      <div className="business-details">
        <div className="business-details-text">
          <h2>{name}</h2>
          {description && <p className="paragraph-large">{description}</p>}
        </div>
      </div>

      <CategoryList />

      <Footer />

      {diningOptionsModalEnabled && <DineInTakeawaySwitchModal />}
      {footerNavigationEnabled && <FooterNavigation showTitle={false} />}
    </div>
  );
}

export default HomePage;
