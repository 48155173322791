import React from 'react';
import { Product } from './Product';

const ColdCaffeineGroup = ({ products }) => {
  return (
    <div>
      <div className="product-list">
        {products
          .filter((p) => [422, 420, 419, 421].indexOf(parseInt(p.id)) == -1)
          .map((product, index) => (
            <Product key={index} product={product} />
          ))}
      </div>

      <div className="product-list">
        <p style={{ textAlign: 'center', color: '#000000', margin: 0, fontWeight: 'bold', fontSize: 22, textDecoration: 'underline' }}>
          ICE DRINKS
        </p>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            {products
              .filter((p) => [422, 420, 419, 421].indexOf(parseInt(p.id)) != -1)
              .map((product, index) => (
                <Product key={index} product={product} />
              ))}
          </div>
          <div>
            <p style={{ fontWeight: 'bold', fontSize: 24 }}>
              <span>S</span> <span style={{ backgroundColor: '#fff', fontSize: 16, padding: '5px 10px', borderRadius: 30 }}>₪ 15</span>
            </p>
            <p style={{ fontWeight: 'bold', fontSize: 24 }}>
              M <span style={{ backgroundColor: '#fff', fontSize: 16, padding: '5px 10px', borderRadius: 30 }}>₪ 20</span>
            </p>
            <p style={{ fontWeight: 'bold', fontSize: 24 }}>
              L <span style={{ backgroundColor: '#fff', fontSize: 16, padding: '5px 10px', borderRadius: 30 }}>₪ 25</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColdCaffeineGroup;
