import React from 'react';
import CategoryDetails from '../../components/CategoryDetails';
import CategoryNavigation from '../../components/CategoryNavigation';
import Footer from '../../components/Footer';
import FooterNavigation from '../../components/FooterNavigation';
import Header from '../../components/Header';
import MenuSocialLink from '../../components/MenuSocialLink';
import useMenu from '../../hooks/useMenu';
import useQRCode from '../../hooks/useQRCode';
import ToppingWidget from './ToppingWidget';
import useCart from '../../hooks/useCart';

function M24LayoutTemplate() {
  const { uuid, footerNavigationEnabled } = useQRCode();
  const { showLocalSwitch, name } = useMenu();
  const { allowToOrder } = useCart();

  return (
    <div className="single-page-layout m24-lauout main-card">
      <Header
        showBackButton={false}
        showShareButton={true}
        showLocalSwitch={showLocalSwitch}
        showBusinessDetails={true}
        backURL={'/m/' + uuid}
      />

      <div className="body">
        <MenuSocialLink containerStyle={{ marginTop: 15 }} />

        <h1 className="brand-name">{name}</h1>

        <CategoryNavigation />

        <CategoryDetails topWidget={!allowToOrder ? <ToppingWidget /> : null} />
      </div>
      <Footer />
      {footerNavigationEnabled && <FooterNavigation iconSize={32} />}
    </div>
  );
}

export default M24LayoutTemplate;
