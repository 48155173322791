import React from 'react';

function Loading() {
  return (
    <dev className="loading">
      <img alt="" src="/assets/logo.png" />
      <dev className="loading-1" />
      <dev className="loading-2" />
    </dev>
  );
}

export default Loading;
