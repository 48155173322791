import React from 'react';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import useMenu from '../../hooks/useMenu';
import CategoryNavigation from './../../components/CategoryNavigation';
import Group from './components/Group';

const ProductListPage = () => {
  const { showLocalSwitch, selectedCategory } = useMenu();

  return (
    <div className="two-page-layout product-list-page main-card uncle-osaka-template">
      <Header showBackButton={false} showShareButton={true} showLocalSwitch={showLocalSwitch} showBusinessDetails={true} />

      <CategoryNavigation />

      <div style={{ padding: 30 }}>
        {selectedCategory?.groups
          .sort((a, b) => parseFloat(a.sequence) - parseFloat(b.sequence))
          .map((group) => (
            <Group key={group.id} group={group} />
          ))}
      </div>
      <Footer />
    </div>
  );
};

export default ProductListPage;
