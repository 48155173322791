import React from 'react';
import { Product } from './Product';

const HotDrinksGroup = ({ products }) => {
  return (
    <div>
      <div className="product-list">
        {products
          .filter((p) => [565, 566, 568, 569, 570].indexOf(parseInt(p.id)) == -1)
          .map((product, index) => (
            <Product key={index} product={product} />
          ))}
      </div>

      <div className="product-list">
        {products
          .filter((p) => [565, 566, 568].indexOf(parseInt(p.id)) !== -1)
          .map((product, index) => (
            <Product key={index} product={product} />
          ))}
      </div>

      <div className="product-list">
        {products
          .filter((p) => [569, 570].indexOf(parseInt(p.id)) !== -1)
          .map((product, index) => (
            <Product key={index} product={product} />
          ))}
      </div>
    </div>
  );
};

export default HotDrinksGroup;
