import React from 'react';
import { Product } from './Product';

const MojitoGroup = ({ products }) => {
  const redBullProduct = products.find((p) => p.id == 545);
  const baseProduct = products.filter(
    (p) => [518, 529, 528, 527, 530, 531, 532, 519, 520, 521, 522, 523, 524, 525, 526, 534, 533].indexOf(parseInt(p.id)) !== -1
  );
  const addedProduct = products.filter((p) => [535, 541, 536, 537, 538, 538, 542, 543, 544, 539].indexOf(parseInt(p.id)) !== -1);

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }} className="top-list">
        <div className="product-list" style={{ border: '1px dashed #ffffff' }}>
          {baseProduct.map((product, index) => (
            <Product key={index} product={product} />
          ))}
        </div>

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <p className="products-price">₪ 25.0</p>
          <div className="product-list" style={{ border: '1px dashed #ffffff' }}>
            {addedProduct.map((product, index) => (
              <Product key={index} product={product} />
            ))}
          </div>
        </div>
      </div>

      {redBullProduct && (
        <div className="product-list" style={{ border: '1px dashed #ffffff' }}>
          <Product product={redBullProduct} />
        </div>
      )}
    </>
  );
};

export default MojitoGroup;
