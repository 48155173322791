import React from 'react';

function CustomCategory({ category }) {
  return (
    <div className="category-details">
      <div dangerouslySetInnerHTML={{ __html: category.htmlContent }} />
    </div>
  );
}

export default CustomCategory;
