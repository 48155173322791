import React from 'react';
import useConfig from '../../hooks/useConfig';
import useMenu from '../../hooks/useMenu';
import GroupDetails from '../GroupDetails';
import CustomCategory from './CustomCategory';

function CategoryDetails({ topWidget }) {
  const { selectedCategory, getProductsInfoByIds, dineInExtraCostEnabled } = useMenu();
  const { dineInOrTakeaway } = useConfig();
  const isCustomCategory = selectedCategory && selectedCategory.isCustom === '1';

  return isCustomCategory ? (
    <CustomCategory category={selectedCategory} />
  ) : (
    <div className="category-details">
      {topWidget}

      {selectedCategory &&
        selectedCategory.groups &&
        selectedCategory.groups
          .sort((a, b) => a.sequence - b.sequence)
          .map((group) => {
            return <GroupDetails key={group.id} group={group} />;
          })}
    </div>
  );
}

export default CategoryDetails;
