import React from 'react';
import { Product } from './Product';

const WafflesGroup = ({ products }) => {
  return (
    <div>
      <div className="product-list">
        {products
          .filter((p) => [501, 502, 503, 504, 1415].indexOf(parseInt(p.id)) == -1)
          .map((product, index) => (
            <Product key={index} product={product} />
          ))}
      </div>

      <div className="product-list">
        {products
          .filter((p) => [501, 502, 503, 504].indexOf(parseInt(p.id)) != -1)
          .map((product, index) => (
            <Product key={index} product={product} />
          ))}
      </div>
      <div className="product-list">
        {products
          .filter((p) => parseInt(p.id) === 1415)
          .map((product, index) => (
            <Product key={index} product={product} />
          ))}
      </div>
    </div>
  );
};

export default WafflesGroup;
