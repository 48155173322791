import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StarRatings from 'react-star-ratings';
import useProduct from '../../hooks/useProduct';
import useQRCode from '../../hooks/useQRCode';
import AddToCart from '../../components/AddToCart';
import FooterNavigation from '../../components/FooterNavigation';
import Header from '../../components/Header';
import MerchandisingAssociations from '../../components/MerchandisingAssociations';
import MiniCart from '../../components/MiniCart';
import ProductDefiningAttribute from '../../components/ProductDefiningAttribute';
import ProductFullImage from '../../components/ProductFullImage';
import ProductPrice from '../../components/ProductPrice';
import ProductReviews from '../../components/ProductReviews';
import MenuService from '../../services/menu';
import useCart from '../../hooks/useCart';
import useMenu from '../../hooks/useMenu';

function ProductDetailsPage(props) {
  const { t } = useTranslation();
  const { reviewEnabled } = useMenu();
  const { uuid, footerNavigationEnabled } = useQRCode();
  const { allowToOrder } = useCart();
  const { availableItems, type, items, id, name, description, rate, merchandisingAssociations } = useProduct();

  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [selectedAssociations, setSelectedAssociations] = useState([]);

  const resolveSelectedProductId = () => {
    if (type === 'COMPLEX_PRODUCT') {
      if (availableItems.length === 1) {
        return availableItems[0].id;
      }
      let filterItems = items;
      selectedAttributes.forEach((element) => {
        filterItems = filterItems?.filter((i) => {
          for (let x = 0; x < i.attribute.length; x++) {
            if (i.attribute[x]['attribute_' + element.attributeId] == element.selectedValueId) {
              return true;
            }
          }
        });
      });

      if (selectedAttributes.length > 0 && filterItems.length > 0) {
        return filterItems[0].id;
      }

      return null;
    }
    return id;
  };

  useEffect(() => {
    if (id) {
      MenuService.sendTrackEvent('PRODUCT_VIEW', { productId: id });
      window.scrollTo(0, 0);
    }
  }, [id]);

  return (
    <div className="two-page-layout home-page main-card product-details-container">
      <Header
        showBackButton={true}
        showShareButton={false}
        showLocalSwitch={false}
        showBusinessDetails={false}
        backURL={'/m/' + uuid + ''}
      />

      <>
        <ProductFullImage productId={id} />

        <div className="product-details">
          <div className="product-title">
            <p>{name}</p>
          </div>
          {props.menu?.settings?.RATE_ENABLED === 'true' && (
            <div className="product-rating">
              <StarRatings
                rating={parseFloat(rate || 0)}
                starDimension="25px"
                starSpacing="8px"
                starRatedColor="#5777ba"
                numberOfStars={5}
                name="rating"
              />
            </div>
          )}
          <div className="product-price">
            <div className="product-offer-price">
              <ProductPrice selectedItemId={resolveSelectedProductId()} productId={id} />
            </div>
            <p className="product-list-price"></p>
          </div>
          {description && (
            <div className="product-description">
              <p className="product-description-title">{t('Details')}:</p>
              <p className="product-description-value">{description}</p>
            </div>
          )}

          {type === 'COMPLEX_PRODUCT' && availableItems.length > 1 && (
            <ProductDefiningAttribute onChange={setSelectedAttributes} productId={id} />
          )}

          {merchandisingAssociations && merchandisingAssociations.length > 0 && (
            <MerchandisingAssociations onChange={setSelectedAssociations} productId={id} />
          )}

          {allowToOrder && (
            <div className="product-actions">
              <AddToCart productId={resolveSelectedProductId()} showNote={true} merchandisingAssociations={selectedAssociations} />
            </div>
          )}
          {reviewEnabled && <ProductReviews productId={id} />}
        </div>
      </>
      {allowToOrder && <MiniCart />}
      {footerNavigationEnabled && <FooterNavigation />}
    </div>
  );
}

export default ProductDetailsPage;
