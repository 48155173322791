import React from 'react';
import { Product } from './Product';

const YogurtGroup = ({ products }) => {
  const groups = [
    [546, 548, 547],
    [558, 559, 560, 561, 562, 563, 564],
  ];

  return (
    <div>
      <div className="product-list">
        {products
          .filter((p) => groups[0].indexOf(parseInt(p.id)) != -1)
          .map((product, index) => (
            <Product key={index} product={product} />
          ))}
      </div>

      <div className="product-list">
        {products
          .filter((p) => groups[1].indexOf(parseInt(p.id)) == -1 && groups[0].indexOf(parseInt(p.id)) == -1)
          .map((product, index) => (
            <Product key={index} product={product} />
          ))}
      </div>

      <div className="product-list">
        {products
          .filter((p) => groups[1].indexOf(parseInt(p.id)) != -1)
          .map((product, index) => (
            <Product key={index} product={product} />
          ))}
      </div>
    </div>
  );
};

export default YogurtGroup;
