import React from 'react';
import CategoryList from '../../components/CategoryList';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import useMenu from '../../hooks/useMenu';

function HomePage() {
  const { showLocalSwitch, name } = useMenu();
  return (
    <div className="two-page-layout home-page main-card">
      <Header showBackButton={false} showShareButton={true} showLocalSwitch={showLocalSwitch} showBusinessDetails={true} />
      <h1 style={{ textAlign: 'center', fontSize: 42 }}>{name}</h1>
      <CategoryList />
      <Footer />
    </div>
  );
}

export default HomePage;
