import React from 'react';
import useMenu from '../../hooks/useMenu';
import { Product } from './Product';
import FshafeshGroup from './FshafeshGroup';
import WafflesGroup from './WafflesGroup';
import ColdCaffeineGroup from './ColdCaffeineGroup';
import HotDrinksGroup from './HotDrinksGroup';
import YogurtGroup from './YogurtGroup';
import CocktailGroup from './CocktailGroup';
import MojitoGroup from './MojitoGroup';

const Group = ({ group }) => {
  const { getProductsInfoByIds } = useMenu();

  const products = getProductsInfoByIds(group.items);

  const renderProductList = () => {
    if (group.name === 'FSHAFESH') {
      return <FshafeshGroup products={products} />;
    } else if (group.name === 'WAFFLES') {
      return <WafflesGroup products={products} />;
    } else if (group.name === 'COLD CAFFEINE') {
      return <ColdCaffeineGroup products={products} />;
    } else if (group.name === 'HOT DRINKS') {
      return <HotDrinksGroup products={products} />;
    } else if (group.name === 'YOGURT') {
      return <YogurtGroup products={products} />;
    } else if (group.name === 'COCKTAIL') {
      return <CocktailGroup products={products} />;
    } else if (group.name === 'MOJITO') {
      return <MojitoGroup products={products} />;
    } else {
      return (
        <div className="product-list">
          {products.map((product, index) => (
            <Product key={index} product={product} />
          ))}
        </div>
      );
    }
  };

  return (
    <div className="category-group" group-id={group.id}>
      <div className="category-group-details">
        <img src={group.thumbnail} alt={group.name} />
        {group.description && <div className="group-description">{group.description}</div>}
      </div>

      {renderProductList()}
    </div>
  );
};

export default Group;
